header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header_container{
    text-align: center;
    position: relative;
    height: 100%;
}


/* =============== CTA ========== */
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ============ HEADER SOCIALS ============== */
.header_social{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .8rem;
    position: absolute;
    left: 0;
    bottom: 15rem;
}

.header_social::after{
    content: "";
    width: 1px;
    height: 2rem;
    background-color: var(--color-primary);
}


/* ============= ME ============== */
.header_container .me{
    /* background-color: var(--color-primary); */
    background-image: linear-gradient(var(--color-primary), var(--color-bg));
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 4rem;
    overflow: hidden;
    border-radius: 12rem 12rem 0 0 ;
    /* opacity: .9; */
    /* padding: 5rem 1.5rem 1.5rem 1.5rem; */
}

/* ============= SCROLL DOWN ============== */
.scroll_down{
    position: absolute;
    right: -2.3rem;
    bottom: 15rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: .9rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ============= */
@media screen and (max-width:1024px) {
    header{
        height: 88vh;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width:600px) {
    header{
        height: 100vh;
    }
    .header_social, .scroll_down{
        display: none;
    }
} 