@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
*{
    margin: 0%;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    /* text-align: center; */
}

:root{
    /* --color-bg: #1f1f3f; */
    --color-bg: #0a0a0a;
    --color-bg-variant: #1a1a1a;
    /* --color-bg-variant: #2c2c6c; */
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255,255,255, 0.6);
    
    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html{
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
 display: none;
}


body{
    width: 100%;
    height: 100vh;
    background-color: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    /* background-image: url("https://images.unsplash.com/photo-1581084121296-8b65c4f80452?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"); */
}


/* ==================GENERAL STYLES ==========*/
.container{
    width: var(--container-width-lg);
    margin: 0 auto;
    text-align: center;
}

h1, h2, h3, h4, h5{
    font-size: 500;
}

section{
    margin-top: 8rem;
    /* height: 100vh; */
}

section > h2, section h5{
    text-align: center;
    color: var(--color-light);
}

section > h2{
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light{
    color: var(--color-light);
}

a{
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover{
    color: var(--color-white);
}

.btn{
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover{
    background-color: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary{
    background-color: var(--color-primary);
    color: var(--color-bg);
}

img{
    display: block;
    width: 100%;
    object-fit: cover;
}




/* ============= MEDIA QUERIES (MIDIUM DEVICES) ============== */
@media screen and (max-width: 1024px) {
    
    .container{
        width: var(--container-width-md);
    }
    section{
        margin-top: 6rem;
    }
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ============== */
@media screen and (max-width: 600px) {
    
    .container{
        width: var(--container-width-sm);
    }
    section h2{
        margin-bottom: 2rem;
    }
}